import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from '@aws-amplify/core';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Amplify.configure({
  ['Auth']: {
    identityPoolId: environment.identityPoolId,
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
  },
});

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.locale('ja');
dayjs.tz.setDefault('Asia/Tokyo');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
