import { HttpContextToken } from '@angular/common/http';
import { UAParser } from 'ua-parser-js';

export const HIDE_PROGRESS = new HttpContextToken(() => false);

export const DEVICE_TYPES = ['pc', 'handset'] as const;
export type DeviceType = (typeof DEVICE_TYPES)[number];

const ua = UAParser(navigator.userAgent);
export const UA_DEVICE_TYPE: DeviceType = ua.device.type === 'mobile' ? 'handset' : 'pc';

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && typeof value === 'object';
}

export function toStrictEntries<T extends Record<string, unknown>>(value: T): [keyof T, unknown][] {
  return Object.entries(value);
}
