import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { map, noop, tap } from 'rxjs';

import { AuthUsecase } from '../usecases/auth.usecase';

export class AuthGuard {
  static canActivate: CanActivateFn = () => {
    const authUsecase = inject(AuthUsecase);
    const router = inject(Router);
    return authUsecase.authState$.pipe(
      map(({ status }) => status === 'signedIn'),
      tap(canActivate => (canActivate ? noop : router.navigate(['']))),
    );
  };

  static canMatch: CanMatchFn = () => {
    const authUsecase = inject(AuthUsecase);
    const router = inject(Router);
    return authUsecase.authState$.pipe(
      map(({ status }) => status === 'signedIn'),
      tap(canActivate => (canActivate ? noop : router.navigate(['']))),
    );
  };
}
