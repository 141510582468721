import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Observable, defer, merge, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthSession, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';

@Injectable()
export class AuthService extends AuthGateway {
  currentUser(bypassCache = false): Observable<AuthUser> {
    const currentUser$ = defer(() => Auth.currentUserPoolUser({ bypassCache }));
    const bypassCache$ = new Observable<never>(observer => {
      const cancel = Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
          case 'tokenRefresh':
            cancel();
            observer.complete();
            break;
          case 'tokenRefresh_failure':
            cancel();
            observer.error(data);
            break;
          default:
            // noop.
            break;
        }
      });
      timer(4e4).subscribe(() => {
        cancel();
        observer.error(new Error('Token refresh timeout.'));
      });
    });
    return bypassCache ? merge(bypassCache$, currentUser$) : currentUser$;
  }

  currentSession(): Observable<AuthSession> {
    return defer(() => Auth.currentSession()).pipe(map(session => ({ token: session.getIdToken().getJwtToken() })));
  }

  signIn(username: string, password: string): Observable<AuthUser> {
    return defer(() => Auth.signIn({ username, password }));
  }

  completeNewPassword(user: AuthUser, password: string): Observable<AuthUser> {
    return defer(() => Auth.completeNewPassword(user, password, false));
  }

  signOut(): Observable<unknown> {
    return defer(() => Auth.signOut());
  }

  forgotPassword(username: string): Observable<unknown> {
    return defer(() => Auth.forgotPassword(username));
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<string> {
    return defer(() => Auth.forgotPasswordSubmit(username, code, password));
  }
}
