import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Prediction } from '../models/prediction.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { PredictionGateway } from '../usecases/prediction.gateway';

@Injectable()
export class PredictionService extends PredictionGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  listPredictionWords(words: string): Observable<Prediction> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.get<Prediction>(`api/ask/predictions/${words}`, options);
  }
}
