import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Completion, CompletionCreateParams } from '../models/completion.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { CompletionGateway } from '../usecases/completion.gateway';

@Injectable()
export class CompletionService extends CompletionGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  createCompletion(params: CompletionCreateParams): Observable<Completion> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.post<Completion>('api/ask/completions', params, options);
  }
}
