import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  AsyncSubject,
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  filter,
  finalize,
  forkJoin,
  from,
  map,
  mergeMap,
  tap,
} from 'rxjs';

import { ChatMessage, Feedback, RecordFeedbackParams } from '../models/chat.model';
import { CompletionCreateParams } from '../models/completion.model';
import { UnitName, UnitNameItem } from '../models/prediction.model';
import { AuthUsecase } from '../usecases/auth.usecase';
import { ChatUsecase } from '../usecases/chat.usecase';
import { CompletionGateway } from '../usecases/completion.gateway';
import { PredictionGateway } from '../usecases/prediction.gateway';
import { RecodeGateway } from '../usecases/record.gateway';
import { ReferenceGateway } from '../usecases/reference.gateway';
import { TimeGateway } from '../usecases/time.gateway';

@Injectable()
export class ChatInteractor extends ChatUsecase {
  get messages$(): Observable<ChatMessage[]> {
    return this._messages;
  }
  get progress$(): Observable<boolean> {
    return this._progress;
  }
  get unitNames$(): Observable<UnitName[]> {
    return combineLatest([this._unitNames, this._initial]).pipe(map(([unitNames, initial]) => unitNames[initial] || []));
  }
  get predictions$(): Observable<string[]> {
    return this._predictions;
  }
  get isThumbnailDisplay$(): Observable<boolean> {
    return this._isThumbnailDisplay;
  }
  get querySuggestion$(): Observable<string> {
    return this._querySuggestion;
  }

  private readonly _messages = new BehaviorSubject<ChatMessage[]>([]);
  private readonly _progress = new BehaviorSubject<boolean>(false);
  private readonly _unitNames = new BehaviorSubject<UnitNameItem>({});
  private readonly _initial = new BehaviorSubject<string>('');
  private readonly _predictions = new BehaviorSubject<string[]>([]);
  private readonly _isThumbnailDisplay = new BehaviorSubject<boolean>(true);
  private readonly _querySuggestion = new Subject<string>();
  private _terms: string[] = [];
  private _searchedTerm = new Set<string>();
  private _synonym: Record<string, string>[] = [];

  constructor(
    private _authUsecase: AuthUsecase,
    private _completionGateway: CompletionGateway,
    private _predictionGateway: PredictionGateway,
    private _recodeGateway: RecodeGateway,
    private _referenceGateway: ReferenceGateway,
    private _timeGateway: TimeGateway,
  ) {
    super();
    this._authUsecase.authState$.pipe(filter(({ status }) => status === 'signedIn')).subscribe(() => {
      this.reset();
      this._referenceGateway.getReference(btoa('unitname/UnitNameTable.json.gz')).subscribe(reference => {
        this._referenceGateway.getUnitNameTable(reference.url).subscribe(response => this._unitNames.next(response));
      });
    });
  }

  send(params: CompletionCreateParams, userInputTime: number): Observable<never> {
    if (this._progress.value) {
      return EMPTY;
    }
    const startTime = Date.now();
    const result = new AsyncSubject<never>();
    this._progress.next(true);
    this._messages.next([
      ...this._messages.value,
      {
        type: 'question',
        message: params.question,
        timestamp: dayjs().unix(),
        references: [],
        queryId: '',
        date: 0,
        isBestSelected: false,
        isBetterSelected: false,
        isBadSelected: false,
        suggestions: [],
      },
    ]);
    forkJoin({
      answer: this._completionGateway.createCompletion(params),
      suggestions: this._referenceGateway.getQuerySuggestion(params.question),
    })
      .pipe(finalize(() => this._progress.next(false)))
      .subscribe({
        next: ({ answer: { answer, resources, queryId, date, executionTime: createCompletionTime }, suggestions }) => {
          const data = [
            ...this._messages.value,
            {
              type: 'answer',
              message: answer,
              references: resources.map((resource, index) => ({
                docTitle: resource.docTitle,
                docPages: resource.docPages,
                docFile$: this._referenceGateway.getReference(resource.docKey).pipe(
                  tap(response =>
                    this._timeGateway
                      .updateExecutionTime(date, {
                        processName: `getReferenceTime_${index}`,
                        executionTime: response.executionTime,
                      })
                      .subscribe(),
                  ),
                  map(response => ({ url: response.url })),
                ),
                originalPage: resource.originalPage,
                summaries: resource.summaries,
                words: resource.words,
                thumbnailUrl: atob(resource.thumbnailUrl),
              })),
              timestamp: dayjs().unix(),
              queryId,
              date,
              isBestSelected: false,
              isBetterSelected: false,
              isBadSelected: false,
              suggestions: [],
            },
          ] as ChatMessage[];
          if (suggestions.query.length) {
            data.push({
              type: 'suggestion',
              message: '',
              timestamp: dayjs().unix(),
              references: [],
              queryId: '',
              date: 0,
              isBestSelected: false,
              isBetterSelected: false,
              isBadSelected: false,
              suggestions: suggestions.query,
            });
          }
          this._messages.next(data);
          const endTime = Date.now();
          from([
            {
              processName: 'userInputTime',
              executionTime: userInputTime,
            },
            {
              processName: 'createCompletionTime',
              executionTime: createCompletionTime,
            },
            {
              processName: 'displayTime',
              executionTime: endTime - startTime,
            },
          ])
            .pipe(mergeMap(param => this._timeGateway.updateExecutionTime(date, param)))
            .subscribe();
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result;
  }

  reset(): void {
    this._messages.next([
      {
        type: 'answer',
        message: '質問内容を入力して下さい。',
        timestamp: dayjs().unix(),
        references: [],
        queryId: '',
        date: 0,
        isBestSelected: false,
        isBetterSelected: false,
        isBadSelected: false,
        suggestions: [],
      },
    ]);
  }

  feedback(button: Feedback, message: ChatMessage): void {
    if (button === 'best') {
      message.isBestSelected = !message.isBestSelected;
      message.isBetterSelected = false;
      message.isBadSelected = false;
    } else if (button === 'better') {
      message.isBetterSelected = !message.isBetterSelected;
      message.isBestSelected = false;
      message.isBadSelected = false;
    } else if (button === 'bad') {
      message.isBadSelected = !message.isBadSelected;
      message.isBestSelected = false;
      message.isBetterSelected = false;
    }

    const rate = message.isBestSelected ? 2 : message.isBetterSelected ? 1 : message.isBadSelected ? -1 : 0;
    const params: RecordFeedbackParams = { rate, date: message.date };
    this._recodeGateway.updateFeedbuck(message.queryId, params).subscribe();
  }

  fetchUnitNames(unitName: string | null): Observable<never> {
    if (!unitName) {
      this._initial.next('');
    } else {
      this._initial.next(unitName.charAt(0));
    }
    return EMPTY;
  }

  fetchPredictionWords(words: string | null): Observable<never> {
    if (!words) {
      this.clearPredictionWords();
      return EMPTY;
    }
    const searchTerms = words.split(' ').filter(word => word !== '');
    const applySynonym = () => {
      searchTerms.forEach((term, index) => {
        this._synonym.forEach(item => {
          searchTerms[index] = item[term] || term;
        });
      });
    };
    const isTermMatch = (term: string): boolean => {
      const termsArray = term.split(' ');
      return searchTerms.every(searchTerm => {
        return termsArray.some(word => word === searchTerm);
      });
    };
    const processPredictions = (terms: string[]): void => {
      const combinations = terms.flatMap(term => this.generateCombinations(searchTerms, term.split(' '))).filter(arr => arr.length !== 0);
      const removeDuplicates = Array.from(new Set(combinations));
      this._predictions.next(removeDuplicates);
    };

    applySynonym();
    const includesTerms = this._terms.filter(term => isTermMatch(term));
    if (includesTerms.length && this._searchedTerm.has(searchTerms[0])) {
      processPredictions(includesTerms);
      return EMPTY;
    }
    const encodedWords = encodeURIComponent(words);
    const result = new AsyncSubject<never>();
    this._predictionGateway.listPredictionWords(encodedWords).subscribe({
      next: ({ predictions, replacementSynonym }) => {
        replacementSynonym.forEach(synonym => this._synonym.push({ [synonym.original]: synonym.synonym }));
        applySynonym();
        if (predictions.length) {
          this._terms = Array.from(new Set([...this._terms, ...predictions]));
          this._searchedTerm.add(searchTerms[0]);
          const includesList = this._terms.filter(term => isTermMatch(term));
          processPredictions(includesList);
        } else {
          this.clearPredictionWords();
        }
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result;
  }

  clearPredictionWords(): void {
    this._predictions.next([]);
  }

  selectPredictions(selectedIndex: number): string {
    return this._predictions.value[selectedIndex];
  }

  setThumbnailSetting(isDisplay: boolean): void {
    this._isThumbnailDisplay.next(isDisplay);
  }

  postQuerySuggestion(suggestion: string): void {
    this._querySuggestion.next(suggestion);
  }

  private generateCombinations(inputChars: string[], originals: string[]): string[] {
    const results: string[] = [];
    const inputLen = inputChars.length;
    const originalsCopy = originals.slice();

    const finalOriginalsCopy = (() => {
      const sortedInput = inputChars.slice().sort((a, b) => originalsCopy.indexOf(a) - originalsCopy.indexOf(b));
      if (JSON.stringify(sortedInput) !== JSON.stringify(inputChars)) {
        const tempOriginalsCopy = originalsCopy.slice();
        sortedInput.forEach((char, i) => {
          const originalIndex = originalsCopy.indexOf(char);
          tempOriginalsCopy[originalIndex] = inputChars[i];
        });
        return tempOriginalsCopy;
      }
      return originalsCopy;
    })();

    if (finalOriginalsCopy[finalOriginalsCopy.length - 1] in inputChars) {
      const filtered = finalOriginalsCopy.filter(x => !inputChars.includes(x)).sort();
      const combinedStr = `${inputChars.join(' ')} ${filtered.join(' ')}`.trim();
      results.push(combinedStr);
      return results;
    }

    if (inputLen === 1) {
      const char = inputChars[0];
      const index = finalOriginalsCopy.indexOf(char);
      if (index === 0) {
        const combinedStr = `${char} ${finalOriginalsCopy[index + 1]}`.trim();
        results.push(combinedStr);
      } else {
        const initialCombinedStr = `${char} ${finalOriginalsCopy.slice(0, index).join(' ')}`.trim();
        results.push(initialCombinedStr);
        if (index + 1 < finalOriginalsCopy.length) {
          const combinedStr = `${initialCombinedStr} ${finalOriginalsCopy[index + 1]}`.trim();
          results.push(combinedStr);
        }
      }
    } else if (
      inputLen > 1 &&
      inputChars.every(
        (char, i) => i === inputLen - 1 || finalOriginalsCopy.indexOf(char) + 1 === finalOriginalsCopy.indexOf(inputChars[i + 1]),
      )
    ) {
      const initialCombinedStr = inputChars.join(' ');
      const index = finalOriginalsCopy.indexOf(inputChars[inputLen - 1]);
      if (index + 1 < finalOriginalsCopy.length) {
        const combinedStr = `${initialCombinedStr} ${finalOriginalsCopy.slice(0, finalOriginalsCopy.indexOf(inputChars[0])).join(' ')} ${
          finalOriginalsCopy[index + 1]
        }`
          .replace(/ {2}/g, ' ')
          .trim();
        results.push(combinedStr);
      } else {
        const combinedStr = initialCombinedStr.trim();
        results.push(combinedStr);
      }
    } else if (inputLen > 1) {
      const minIndex = Math.min(...inputChars.map(char => finalOriginalsCopy.indexOf(char)));
      const maxIndex = Math.max(...inputChars.map(char => finalOriginalsCopy.indexOf(char)));
      const betweenChars = finalOriginalsCopy.slice(minIndex + 1, maxIndex).filter(char => !inputChars.includes(char));
      const initialCombinedStr = inputChars.concat(betweenChars).join(' ');
      if (maxIndex + 1 < finalOriginalsCopy.length) {
        const combinedStr = `${initialCombinedStr} ${finalOriginalsCopy[maxIndex + 1]}`.replace(/ {2}/g, ' ').trim();
        results.push(combinedStr);
      } else {
        const combinedStr = initialCombinedStr.trim();
        results.push(combinedStr);
      }
    }
    return results;
  }
}
