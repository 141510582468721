import { Observable } from 'rxjs';

import { QuerySuggestion, Reference } from '../models/chat.model';
import { UnitNameItem } from '../models/prediction.model';

export abstract class ReferenceGateway {
  abstract getReference(docKey: string): Observable<Reference>;
  abstract getUnitNameTable(url: string): Observable<UnitNameItem>;
  abstract getQuerySuggestion(question: string): Observable<QuerySuggestion>;
}
