import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ExecutionTimeParam } from '../models/time.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { TimeGateway } from '../usecases/time.gateway';

@Injectable()
export class TimeService extends TimeGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  updateExecutionTime(date: number, params: ExecutionTimeParam): Observable<{ message: string }> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.put<{ message: string }>(`api/ask/times/${date}`, params, options);
  }
}
