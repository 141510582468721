import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, catchError, filter, finalize, map, of } from 'rxjs';

import { AuthState, AuthUser } from '../models/auth.model';
import { AuthGateway } from '../usecases/auth.gateway';
import { AuthUsecase } from '../usecases/auth.usecase';
import { ProgressUsecase } from '../usecases/progress.usecase';

@Injectable()
export class AuthInteractor extends AuthUsecase {
  get authState$(): Observable<AuthState> {
    return this._authState.pipe(filter(({ status }) => status !== 'none'));
  }
  get token$(): Observable<string> {
    return this._authGateway.currentSession().pipe(map(session => session.token));
  }

  private readonly _authState = new BehaviorSubject<AuthState>({ status: 'none' });

  constructor(
    private _progressUsecase: ProgressUsecase,
    private _authGateway: AuthGateway,
  ) {
    super();
    this.refreshCache();
  }

  refreshCache(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .currentUser(true)
      .pipe(
        catchError(() => of(undefined)),
        finalize(() => this._progressUsecase.dismiss(progressId)),
      )
      .subscribe({
        next: user => {
          if (user) {
            this.setAuthState({ status: 'signedIn', user });
          } else {
            this.setAuthState({ status: 'signIn', user });
          }
        },
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  signIn(username: string, password: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .signIn(username, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: user => {
          if (!user.challengeName) {
            this.setAuthState({ status: 'signedIn', user });
            return;
          }
          switch (user.challengeName) {
            case 'NEW_PASSWORD_REQUIRED':
              this.setAuthState({ status: 'requireNewPassword', user });
              break;
            default:
              result.error(new Error());
              break;
          }
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  completeNewPassword(password: string): Observable<never> {
    const { user } = this._authState.value;
    if (!user) {
      throw new Error('Not signed in.');
    }
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .completeNewPassword(user, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          const username = user.challengeParam?.userAttributes.email;
          this.setAuthState({ status: 'signIn', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  signOut(): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .signOut()
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'signIn' });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  forgotPassword(username: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPassword(username)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'resetPassword', user: { username } as AuthUser });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  forgotPasswordSubmit(username: string, code: string, password: string): Observable<never> {
    const progressId = this._progressUsecase.show();
    const result = new AsyncSubject<never>();
    this._authGateway
      .forgotPasswordSubmit(username, code, password)
      .pipe(finalize(() => this._progressUsecase.dismiss(progressId)))
      .subscribe({
        next: () => {
          this.setAuthState({ status: 'signIn' });
        },
        error: result.error.bind(result),
        complete: result.complete.bind(result),
      });
    return result.asObservable();
  }

  setAuthState(state: AuthState): void {
    this._authState.next(state);
  }
}
