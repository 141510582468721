import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { QuerySuggestion, Reference } from '../models/chat.model';
import { UnitNameItem } from '../models/prediction.model';
import { HIDE_PROGRESS } from '../models/utility.model';
import { ReferenceGateway } from '../usecases/reference.gateway';

@Injectable()
export class ReferenceService extends ReferenceGateway {
  constructor(private _http: HttpClient) {
    super();
  }

  getReference(docKey: string): Observable<Reference> {
    return this._http.get<Reference>(`api/ask/references/${docKey}`);
  }

  getUnitNameTable(url: string): Observable<UnitNameItem> {
    return this._http.get<UnitNameItem>(url);
  }

  getQuerySuggestion(question: string): Observable<QuerySuggestion> {
    const options = { context: new HttpContext().set(HIDE_PROGRESS, true) };
    return this._http.get<QuerySuggestion>(`api/ask/suggestions/${question}`, options);
  }
}
