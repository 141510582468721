import { Observable } from 'rxjs';

import { AuthSession, AuthUser } from '../models/auth.model';

export abstract class AuthGateway {
  abstract currentUser(bypassCache?: boolean): Observable<AuthUser>;
  abstract currentSession(): Observable<AuthSession>;
  abstract signIn(username: string, password: string): Observable<AuthUser>;
  abstract completeNewPassword(user: AuthUser, password: string): Observable<AuthUser>;
  abstract signOut(): Observable<unknown>;
  abstract forgotPassword(username: string): Observable<unknown>;
  abstract forgotPasswordSubmit(username: string, code: string, password: string): Observable<string>;
}
